import React, { useEffect } from 'react'
import { Container , Row, Col } from 'reactstrap';
import { getReservationByFolio } from '../../api_controllers/reservations_controller';

export default function Summary({data, page}) {
    // console.log(data);
    return (
            <Container>
                <Row>
                    <Col xs={12}>
                        <h2>Traveler information</h2>
                        <h3>{data?.trip_type}</h3>
                    </Col>                    
                    <Col xs={12} md={page === 'confirmation'? 6 : 12}>
                        <h5 style={{marginBottom:'1rem',marginTop:'1.5rem'}}>Customer information</h5>
                        <h6>Full name: <span>{data?.fullname}</span></h6>
                        <h6>Phone: <span>{data?.cellphone}</span></h6>
                        <h6>E-mail: <span>{data?.email}</span></h6>
                        <h5 style={{marginBottom:'1rem',marginTop:'1.5rem'}}>Transport selected</h5>
                        <h6>Vehicle: <span>{data?.unit}</span></h6>
                        <h6>Trip type: <span>{data?.trip_type}</span></h6>
                        <h6>Passenger number: <span>{data?.total_passengers}</span></h6>
                        <h6>Pickup location: <span>{data?.pickup_location}</span></h6>
                        <h6>Destination: <span>{data?.destination_location}</span></h6>
                    </Col>
                    <Col xs={12} md={page === 'confirmation'? 6 : 12}>
                        <h5 style={{marginBottom:'1rem',marginTop:'1.5rem'}}>Pickup Information:</h5>
                        <h6>Date / Time: <span>{data?.arrival_datetime}</span></h6>
                        <hr />
                        <h5 style={{marginBottom:'1rem',marginTop:'1.5rem'}}>Departure information:</h5>
                        <h6>Date / Time: <span>{data?.departure_datetime}</span></h6>                        
                    </Col>
                </Row>
            </Container>
    )
}