import React, { Fragment } from 'react'
import { Container , Row, Col } from 'reactstrap';

import 'moment/locale/cs.js';
import 'moment/locale/es.js';
import 'moment/locale/fr.js';
import 'moment/locale/nl.js';
import moment from 'moment';

export default function Summary(props) {

    const {lang} = props;

    if(lang === 'eng'){
        moment.locale('en');
    }else{
        moment.locale('es');
    }

    return (
        <Fragment>
            <Container className="summary-page">
                <Row className="my-3">
                    <Col xs={8}>
                        <h3>{props.title}</h3>
                    </Col>
                    <Col xs={4} className="text-right">
                        <h4>Total: ${props.totalPayment} USD</h4>
                    </Col>
                </Row>
                <Row className="data-information">
                    <Col xs={12} md={6}>
                        <h4>{ lang === 'eng' ? 'Customer information' : 'Información del cliente'}</h4>
                        <h6>{ lang === 'eng' ? 'Full name:' : 'Nombre:'} <span>{props.data.fullname}</span></h6>
                        <h6>{ lang === 'eng' ? 'Phone:' : 'Telfono:'} <span>{props.data.cellphone}</span></h6>
                        <h6>{ lang === 'eng' ? 'E-mail:' : 'Correo:'} <span>{props.data.email}</span></h6>
                        <h5>{ lang === 'eng' ? 'Transport Selected' : 'Unidad Seleccionada'} </h5> { lang === 'eng' ? '' : ''}
                        <h6>{ lang === 'eng' ? 'Vehicle:' : 'Vehiculo:'} <span>{props.unit.label}</span></h6>
                        <h6>{ lang === 'eng' ? 'Passenger number:' : 'Número de Pasajeros:'} <span>{props.data.total_passengers}</span></h6>
                        <h6>{ lang === 'eng' ? 'Pickup Location:' : 'Ubicación de Recogida'} <span>{props.data.pickup_location}</span></h6>
                        <h6>{ lang === 'eng' ? 'Destination:' : 'Destino:'} <span>{props.data.destination_location}</span></h6>
                    </Col>
                    <Col xs={12} md={6}>
                        <h4>{ lang === 'eng' ? 'Trip type:' : 'Tipo de Servicio'} <span>{props.data.trip_type}</span></h4>
                        <h5>{ lang === 'eng' ? 'Pickup Information:' : 'Información de origen:'}</h5>
                        <h6>{ lang === 'eng' ? 'Date / Time:' : 'Fecha / Hora:'} <span>{ moment(props.data.pickup_datetime).format("dddd, MMMM Do YYYY, h:mm:ss a") }</span></h6>                  
                        <hr />
                        {
                            props.data.trip_type == 'Round Trip' &&
                            <Fragment>
                                <h5>{ lang === 'eng' ? 'Departure information:' : 'Información de Partida:'}</h5>
                                <h6>{ lang === 'eng' ? 'Date / Time:' : 'Fecha / Hora:'} <span>{ moment(props.data.departure_datetime).format("dddd, MMMM Do YYYY, h:mm:ss a") }</span></h6>
                            </Fragment>
                        }
                        
                    </Col>
                </Row>
            </Container>
            <hr />
            {
                lang === 'eng' ?
                <div className="reservations-policies px-3">
                    <p>Transportation from Hotel to La Isla Tours: We encourage to schedule your pick up 1 hour prior to your reservation time if you are in “Zone 1”, 1.5 hours if you’re in “Zone 2 and 3” and 2 hours if you’re in “Zone 4”, you will receive an email or message to confirm your pick up time. Driver has up to 10 minutes courtesy time to wait in case is needed, thank you for your understanding.</p>
                    <p>Transportation from La Isla Tours to Hotel: We will be picking you up 10 minutes after your Tour departure time at Lorenzillo´s restaurant, driver has up to 10 minutes courtesy time to wait in case is needed, thank you for your understanding.</p>
                    <h5>RESERVATION / CANCELATION POLICY</h5>
                    <ul>
                        <li>All reservations are non-transferable.</li>
                        <li>In order to assure availability, we suggest to make your reservations 48hrs in advance.</li>
                        <li>Modifications for transportation from Hotel to La Isla Tours a 24 hours notification must be made prior to schedule.</li>
                        <li>Modifications for transportation from La Isla Tours to Hotel a 24 hours notification must be made prior to schedule.</li>
                        <li>All cancellations must be made at least 4 hours before the service time.</li>
                        <li>Cancellations up to 4 hours in advance wont generate any penalty..</li>
                        <li>Partial or total refunds will be applied according on times, payment method and penalty percentage defined by the payment system chosen. **Claims made after the 30day period or any cancelation that does not meet cancelation criteria mentioned above will be completely non-refundable.</li>
                    </ul>
                </div>
                :
                <div className="reservations-policies px-3">                 
                    <p>Transportation from Hotel to La Isla Tours: We encourage to schedule your pick up 1 hour prior to your reservation time if you are in “Zone 1”, 1.5 hours if you’re in “Zone 2 and 3” and 2 hours if you’re in “Zone 4”, you will receive an email or message to confirm your pick up time. Driver has up to 10 minutes courtesy time to wait in case is needed, thank you for your understanding.</p>
                    <p>Transportation from La Isla Tours to Hotel: We will be picking you up 10 minutes after your Tour departure time at Lorenzillo´s restaurant, driver has up to 10 minutes courtesy time to wait in case is needed, thank you for your understanding.</p>
                    <h5>RESERVATION / CANCELATION POLICY</h5>
                    <ul>
                        <li>All reservations are non-transferable.</li>
                        <li>In order to assure availability, we suggest to make your reservations 48hrs in advance.</li>
                        <li>Modifications for transportation from Hotel to La Isla Tours a 24 hours notification must be made prior to schedule.</li>
                        <li>Modifications for transportation from La Isla Tours to Hotel a 24 hours notification must be made prior to schedule.</li>
                        <li>All cancellations must be made at least 4 hours before the service time.</li>
                        <li>Cancellations up to 4 hours in advance wont generate any penalty..</li>
                        <li>Partial or total refunds will be applied according on times, payment method and penalty percentage defined by the payment system chosen. **Claims made after the 30day period or any cancelation that does not meet cancelation criteria mentioned above will be completely non-refundable.</li>
                    </ul>
                </div>
            }
            
            
        </Fragment>
    )
}