const configs = {
    //DEV =======
    // URL_API_DEV: "http://localhost:4002/api/v1",
    // URL_API_BASE: "http://localhost:4002/api/v1",
    // URL_PAYMENT_PAYPAL: "http://localhost:3000",
    // PRODUCTION  ======================================================
    URL_PAYMENT_PAYPAL: "https://secure.transroute.com.mx",
    URL_API_BASE: "https://api-laislatour.transroute.com.mx/api/v1",

    // BTT LIVE
    PK_LIVE_BTT_STRIPE:"pk_live_51KN04vD1703QVxnAcv04d0JYEJQk3jJvW46SoeZeKTv6oiBCrwFUoCLrSQQ9o2wZZkAXqalnIzqz31PbEF859MAu00h5PRKHND",
    SK_LIVE_BTT_STRIPE:"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",
    //BTT TEST
    PK_TEST_BTT_STRIPE:"pk_test_51KN04vD1703QVxnA4OKiuZMctbnUlZT6oEVoT4weiRyd62gYzZu7kEdqL8AiwqIkkuuHSasTKD2icdMIxS4OEn3N00Nw01uwWG",
    SK_TEST_BTT_STRIPE:"sk_test_51KN04vD1703QVxnAVxyvmvAjV3GqtjUqxGsq6DblfJJAhxeMImpixbUN40KCWvJntEyqGWB7sDn0BPQ69nQsHIOB00VsO6tIpF",


    ENVIRONMENT: "PRODUCTION",
};

export default configs;
