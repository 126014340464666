import axios from 'axios';
import configs from './../config/config';

export const getRates = () => {
    return axios.get(`${configs.URL_API_BASE}/rates`);
}

export const getRateByPlaces = (pickup_id, destination_id, id_unit) => {
    //?origin=3&id_unit=1&destination=3
    return axios.get(`${configs.URL_API_BASE}/rates?origin=${pickup_id}&id_unit=${id_unit}&destination=${destination_id}`);
}