import axios from 'axios';
import configs from './../config/config';

export const getReservations = () => {
    return axios.get(`${configs.URL_API_BASE}/reservations`);
}

export const getReservationByFolio = (folio) => {
    return axios.get(`${configs.URL_API_BASE}/reservations/${folio}`);
}

export const postReservation = (data) => {
    return axios.post(`${configs.URL_API_BASE}/reservations`, data);
}
export const putReservation = (data, id_reservation) => {
    return axios.put(`${configs.URL_API_BASE}/reservations/${id_reservation}`, data);
}